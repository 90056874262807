.c-radio__container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-radio {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem;
}

.c-radio__item {
  appearance: none;
}

.c-radio__item + .c-radio__label {
  background-color: #f1f1f1;
  color: #656565;
  font-family: "PlusJakartaSans-Regular";
}

.c-radio__label {
  position: relative;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  transition-duration: 0.5s;
  transition-property: transform, color, box-shadow;
  transform: none;
}

.c-radio__item:checked + .c-radio__label {
  background-color: #35383d;
  color: #ffffff;
  transform: translateY(-2px);
}

@media only screen and (max-width: 991px) {
  .c-radio__container {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    gap: 0.5rem;
  }
}
