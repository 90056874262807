.c-object-content-container {
  padding-block: 2rem;
  padding-inline: 3rem;
}

.c-object-content-container a {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

.object-name {
  padding-left: 10%;
}

.object-name--hide {
  visibility: hidden;
  font-size: 0;
}

.surrounding-objects-name {
  padding-left: 2rem;
  padding-block: 1rem;
}

.btn--more {
  width: fit-content;
  align-self: flex-end;
  border: none;
  border-radius: 0.5rem;
  background-color: #35383c;
  text-align: center;
  padding: 0.5rem 1.5rem;
}

.btn--more a {
  font-family: "PlusJakartaSans-Medium";
  color: #ffffff;
}

.btn--inform-us {
  width: 100%;
  margin-top: 1.5rem;
  background-color: #35383d;
  border-radius: 1rem;
  font-family: "PlusJakartaSans-Medium";
  color: #ffffff;
  padding: 1rem 1.5rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  gap: 2rem;
}

/*General information*/
.object-category-header-name {
  font-family: "PlusJakartaSans-Medium";
  font-size: 2.5rem;
  outline: 0;
}

.object-category-subheader-name {
  font-family: "PlusJakartaSans-Bold";
  margin-bottom: 0.5rem;
}

.object-category-content {
  font-family: "PlusJakartaSans-Light";
}

.object-category-content a {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

.object-img {
  width: 100%;
  border-radius: 1rem;
  margin-block: 1rem;
}

.c-map__btn-container {
  display: flex;
  justify-content: center;
  padding-block: 2rem;
}

.c-map__btn--campus-map {
  border: none;
  border-radius: 0.5rem;
  background-color: #35383c;
  color: #ffffff;
  text-align: center;
  padding: 0.5rem 1.5rem;
}

.c-map__btn--campus-map img {
  margin-left: 1rem;
}

/*Accessible information*/

.c-accessible-info-list {
  columns: 2;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-accessible-info-list li {
  height: 6em;
  display: flex;
  align-items: center;
  break-inside: avoid-column;
}

.c-accessible-info-list__pictogram {
  border: 1px solid #ebebeb;
  margin-right: 1.5rem;
  padding: 1rem;
  border-radius: 1rem;
}

/*Clickable containers*/

.c-clickable-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.c-clickable-list__link {
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-block: 2rem;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  text-decoration: none;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.c-clickable-list__link span {
  font-family: "PlusJakartaSans-Regular";
  color: #000000;
}

.c-clickable-list__link img {
  transition: transform 0.5s ease-out;
}

.c-clickable-list__link:hover img {
  transform: translateX(5px);
}

/*Navigation area for building structure*/

.navigation-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.navigation-area ul {
  list-style: none;
  padding-inline-start: 0;
}

.navigation-area ul li {
  display: inline;
}

.navigation-area img {
  margin-inline: 0.5rem;
}

.navigation-area a {
  font-family: "PlusJakartaSans-Medium";
  color: #35383d;
  text-decoration: none;
}

.navigation-area a:hover {
  color: #000000;
  text-decoration: underline;
}

.navigation-area a.active {
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

/*Description for profile*/

.object-category-profile-description {
  position: relative;
  border: 1px solid #e5e5e5ff;
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 1rem;
  padding-top: 3rem;
}

.object-category-profile-sign {
  position: absolute;
  top: -1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  background-color: #35383d;
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
}

.object-category-profile-sign__icon {
  margin-right: 1rem;
}

.c-no-data-alert {
  background-color: #e2e3e5;
  border-radius: 0.5rem;
  border: 1px solid #c4c8cb;
  padding: 1rem;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .text--header.object-name {
    visibility: hidden;
    font-size: 0;
  }

  .object-category-header-name {
    display: none;
  }

  .c-object-content-container {
    display: none;
  }

  .c-map__btn--toggle-map {
    display: none;
  }

  .c-accessible-info-list {
    columns: 1;
  }

  .c-accessible-info-list__pictogram {
    align-self: flex-start;
  }

  .navigation-area {
    margin-bottom: 0.6em;
    margin-left: 0;
  }

  /*Description for profile*/
  .object-category-profile-sign {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .object-category-profile-sign__icon {
    margin: 0;
  }

  .object-category-profile-sign__label {
    display: none;
  }

  .object-category-profile-description {
    padding: 0.4em;
    padding-top: 3em;
  }
}

@media only screen and (max-width: 1401px) {
  .c-accessible-info-list {
    columns: 1;
    margin-top: 0;
  }

  .c-accessible-info-list li {
    height: auto;
    margin-block: 0.5rem;
  }
}
