.footer {
  width: 100%;
  background: url(../../assets/footer/footer.svg) no-repeat;
  background-size: cover;
}

.footer-signs {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 17vh;
  background: url(../../assets/footer/footer-signs.svg) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 15rem 1rem 15rem;
}


.footer-decoration {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 1vh;
  background: url(../../assets/footer/footer-decoration-part.svg) no-repeat;
  background-size: cover;
}

.footer .container {
  padding-top: 13vh;
  padding-bottom: 5vh;
}

.contact-info a {
  color: #ffffff;
}

.footer-nav-menu-list {
  list-style: none;
  padding: 0;
}

.footer-nav-menu-list li {
  margin: .3rem 0;
}

.footer-nav-menu-list li a {
  color: #c9c9c9;
  text-decoration: none;
}


.footer-nav-menu-list li a:hover, .footer-nav-menu-list li a:focus, .footer-nav-menu-list li a:active {
  color: #ffffff;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .footer .container {
    width: 80%;
    padding-top: 4rem;
  }

  .address-info, .contact-info {
    margin-block: 1rem;
  }

  .footer-signs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    box-sizing: content-box;
  }

  .footer-signs img {
    padding: 1.5rem 0;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .footer .container {
      padding-top: 4rem;
  }

  .footer-signs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    box-sizing: content-box;
  }

  .footer-signs img {
    padding: 1.5rem 0;
  }

  .footer-decoration {
    height: 2vh;
  }
}