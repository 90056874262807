.navbar-brand {
  z-index: 2;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.menu-container__list {
  list-style: none;
}

.menu-container__list > li {
  display: inline;
  margin-left: 20px;
  margin-right: 20px;
}

.menu-container-list-item__link {
  font-size: 1.05em;
  color: #787678;
  text-decoration: none;
  font-family: "PlusJakartaSans-Regular";
  padding-bottom: 0.8rem;
  position: relative;
  transition: color 0.4s;
  background-color: transparent;
  border: none;
}

.menu-container-list-item__link:hover,
.menu-container-list-item__link:focus {
  color: #000000;
}

.menu-container-list-item__link span {
  margin-left: 0.5em;
}

.menu-container-list-item__link svg g line,
.menu-container-list-item__link svg g line {
  stroke: #787678;
  transition: stroke 0.4s;
}

.menu-container-list-item__link:hover svg g line,
.menu-container-list-item__link:focus svg g line {
  stroke: #000000;
}

/*Dropdown menu*/

.menu-container-dropdown-submenu {
  display: none;
  position: absolute;
  top: 4.5em;
  width: 90%;
  right: 0;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 10px 70px #00000026;
  border-radius: 1em;
  padding: 2em;
}

.menu-container-dropdown-submenu.show {
  display: block;
  z-index: 2;
}

.menu-container-dropdown-submenu ul {
  list-style: none;
  columns: 2;
  margin: 0;
  padding: 0;
}

.menu-container-dropdown-submenu ul > li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  break-inside: avoid-column;
}

.menu-container-dropdown-submenu__link {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  font-family: "PlusJakartaSans-Regular";
  padding-bottom: 0.8rem;
  position: relative;
  transition: all 0.4s;
}

.menu-container-dropdown-submenu__link:hover {
  color: #000000;
}

.menu-container-dropdown-submenu__link:hover
  .menu-container-dropdown-submenu-link__pictogram {
  background-color: #8080800a;
}

.menu-container-dropdown-submenu-link__pictogram {
  border-radius: 10px;
  border: 1px solid #f5f4f4;
  margin-right: 1.3rem;
  padding: 0.8em;
  height: 4em;
}

.menu-container-dropdown-submenu-link__content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.menu-container-dropdown-submenu-link__content div:nth-child(1) {
  font-size: 1.1em;
  font-family: "PlusJakartaSans-Medium";
  margin-bottom: 0.3em;
}

.menu-container-dropdown-submenu-link__content div:nth-child(2) {
  font-size: 0.9em;
}

/*Mobile hamburger menu*/

.mobile-menu-btn {
  width: 60px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: none;
}

.mobile-menu-btn.open {
  z-index: 1;
}

.mobile-menu-btn span {
  background-color: #35383d;
  border-radius: 4px;
  height: 4px;
  margin: 5.2px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition: all 0.4s ease;
}

.mobile-menu-btn span:nth-of-type(1) {
  width: 50%;
}

.mobile-menu-btn span:nth-of-type(2) {
  width: 100%;
}

.mobile-menu-btn span:nth-of-type(3) {
  width: 75%;
}

.mobile-menu-btn.open span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(4px, 0px);
}

.mobile-menu-btn.open span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}

.mobile-menu-btn.open span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(24px, -5px) rotatez(45deg);
}

.mobile-menu-container {
  position: absolute;
  background: #ffffff;
  top: 0;
  left: 0;
  height: 100vh;
  display: none;
}

.mobile-menu-container.open {
  display: block;
  padding: 8rem 0 0 3rem;
}

.mobile-menu-container__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu-container-list-item__link {
  font-size: 1.4rem;
  display: inline-block;
  color: #787678;
  text-decoration: none;
  font-family: "PlusJakartaSans-Medium";
  transition: color 0.4s;
  margin-block: 0.5rem;
}

.mobile-menu-container-list-item__link:hover,
.mobile-menu-container-list-item__link:focus {
  color: #000000;
}

/*Mobile dropdown menu*/

.mobile-menu-container-dropdown-submenu-list {
  list-style: none;
}

.mobile-menu-container-dropdown-submenu__link {
  font-size: 1.3rem;
  display: inline-block;
  color: #787678;
  text-decoration: none;
  font-family: "PlusJakartaSans-Regular";
  transition: color 0.4s;
  margin-block: .5rem;
}

.mobile-menu-container-dropdown-submenu__link:hover,
.mobile-menu-container-dropdown-submenu__link:focus {
  color: #000000;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .navbar .container figure {
    margin: 0 0.8rem 1rem;
  }

  .mobile-menu-btn {
    display: flex;
    flex-direction: column;
    margin-right: 0.5em;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .menu-container-dropdown-submenu {
    width: 120%;
  }
}
