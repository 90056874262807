.btn--more-news {
  width: 25%;
  margin-top: 1.5em;
  background-color: #35383d;
  border-radius: 15px;
  font-family: "PlusJakartaSans-Regular";
  color: #ffffff;
  border: none;
  padding: 0.7em 1em;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.l-filter-container {
  display: none;
  padding: 1em;
  border-radius: 15px;
  margin-bottom: 0.5em;
  border: 0.1px solid #00000032;
}

.l-filter-container.open {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.l-filter-container.open .c-filter-buttons {
  padding-left: 3rem;
  padding-right: 3rem;
}

.c-filter-result-info {
  font-family: "PlusJakartaSans-Regular";
  padding-left: 1.5em;
  padding-top: 0.8em;
  padding-bottom: 0.5em;
  font-size: 1.3rem;
  color: #707070;
}

.c-filter-result-info span {
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

.c-select-building__label {
  font-family: "PlusJakartaSans-Medium";
}

.btn--close,
.btn--reset {
  margin-top: 1em;
  font-family: "PlusJakartaSans-Regular";
  color: #000000;
  background-color: transparent;
  border: none;
  padding: 0.7em 1em;
  text-align: center;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--filter {
  margin-top: 1em;
  background-color: #35383d;
  border-radius: 15px;
  font-family: "PlusJakartaSans-Regular";
  color: #ffffff;
  border: none;
  padding: 0.7em 1em;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1201px) {
  .l-filter-container.open {
    flex-direction: column;
    gap: 1em;
  }

  .l-filter-container.open .c-filter-buttons {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 531px) and (max-width: 991px) {
  .btn--more-news {
    width: 50%;
    margin-top: 1.5em;
    background-color: #35383d;
    border-radius: 15px;
    font-family: "PlusJakartaSans-Regular";
    color: #ffffff;
    border: none;
    padding: 0.7em 1em;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .l-filter-container {
    flex-direction: column;
    gap: 0.5em;
    justify-content: space-around;
  }

  .l-filter-container.open label {
    align-self: flex-start;
  }

  .l-filter-container.open div:nth-child(3) {
    flex-direction: column;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .l-filter-container.open .c-filter-buttons {
    padding-left: 0;
    padding-right: 0;
  }

  .c-filter-result-info {
    padding-left: 0;
  }

  .l-filter-container div:first-child {
    flex-direction: column;
  }
}

@media only screen and (max-width: 530px) {
  .btn--more-news {
    width: 50%;
    margin-top: 1.5em;
    background-color: #35383d;
    border-radius: 15px;
    font-family: "PlusJakartaSans-Regular";
    color: #ffffff;
    border: none;
    padding: 0.7em 1em;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .l-filter-container {
    flex-direction: column;
    gap: 0.5em;
    justify-content: space-around;
  }

  .l-filter-container.open label {
    align-self: flex-start;
  }

  .l-filter-container.open div:nth-child(3) {
    flex-direction: column;
    padding-left: 1em;
    padding-right: 1em;
  }

  .l-filter-container.open .c-filter-buttons {
    padding-left: 0;
    padding-right: 0;
  }

  .c-filter-result-info {
    padding-left: 0;
  }

  .l-filter-container div:first-child {
    flex-direction: column;
  }
}
