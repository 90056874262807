
.l-cookies-info-container {
    width: 500px;
    box-sizing: content-box;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    background-color: #ffffff;
    border: 1px solid #b1b1b140;
    border-radius: 1rem;
    padding: 1rem;
    z-index: 3;
}

.l-cookies-info-container--informed {
    display: none;
}

.c-cookies-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.c-cookies-info-content {
    flex-basis: 70%;
}

.c-cookies-info-content p {
    margin-block: 0;
}

.c-cookies-info-close-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-cookies-info-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    background-color: transparent;
    border: 1px solid #b0b0b093;
    padding: .5rem;
}

/*Media css selectors*/

@media only screen and (max-width: 576px) {
  .l-cookies-info-container {
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}