.l-surrounding-cards-container a {
  text-decoration: none;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .l-surrounding-cards-container {
    width: 90%;
  }
}
