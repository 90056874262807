@font-face {
  font-family: "PlusJakartaSans-Light";
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url(./assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf)
    format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
