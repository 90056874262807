* {
  font-family: "PlusJakartaSans-Light";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  outline: 0;
}

p, li {
  line-height: 1.6;
}

.text--home-header {
  font-family: "PlusJakartaSans-Medium";
  outline: 0;
  margin-block: 2rem;
}

.text--header,
.text--category-header {
  font-family: "PlusJakartaSans-Medium";
  font-size: 2.8rem;
  outline: 0;
  margin-bottom: 2rem;
}

.l-description-box .text--header:nth-child(1) {
  color: #707070;
}

.l-description-box .text--header:nth-child(2) {
  font-weight: 600;
}

.text--subheader {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.6rem;
  outline: 0;
}

h4 {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.2rem;
}

h5 {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.15rem;
}

h6 {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.1rem;
}

/*Text*/

.text {
  font-family: "PlusJakartaSans-Light";
}

.text--uppercase {
  text-transform: uppercase;
}

.text--w-medium {
  font-family: "PlusJakartaSans-Medium";
  font-weight: 400;
}

.text--w-bold {
  font-family: "PlusJakartaSans-Medium";
  font-weight: 600;
}

.text--small {
  font-size: 0.75rem;
}

.text--medium {
  font-size: 1rem;
}

.text--huge {
  font-size: 2rem;
}

.text--gray {
  color: #c9c9c9;
}

.text--white {
  color: #ffffff;
}

.text--center {
  text-align: center;
}

/*No data info*/

.no-data-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-data-info span {
  font-size: 1.4rem;
  font-family: "PlusJakartaSans-Regular";
}

.no-data-info span:nth-of-type(2) {
  text-align: center;
  font-size: 1rem;
  font-family: "PlusJakartaSans-Regular";
  color: #7b7b7b;
  margin-top: 0.75rem;
}

.no-data-info img {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

/*Description container*/

.l-description-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.l-description-box a,
.l-description-box a span {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

.l-description-box p {
  margin-block: 0.5rem;
  margin-left: 1.3rem;
}

.l-description-box ol {
  padding-left: 4rem;
}

/*Search input and button*/

.l-search-container {
  width: 100%;
  position: relative;
  margin: 20px;
  margin-left: 0;
  margin-right: 0;
}

.c-search__input {
  width: 100%;
  height: 80px;
  border-radius: 1rem;
  border: 1px solid #00000032;
  font-size: 1.4rem;
  font-family: "PlusJakartaSans-Light";
  padding: 20px;
  padding-left: 30px;
}

.c-search__input::placeholder {
  color: #707070c0;
}

.c-search__btn-container {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.search__btn {
  width: 70px;
  height: 70%;
  border-radius: 1rem;
  background-color: #35383d;
  border: 0;
  margin-left: 1rem;
}

/*Dropdown search results*/

.c-dropdown-search-results {
  position: absolute;
  width: 100%;
  height: fit-content;
  padding: 1.5em;
  padding-bottom: 0;
  margin-top: 0.5em;
  border-radius: 0.5em;
  background-color: #ffffff;
  box-shadow: 5px 8px 33px -17px #00000040;
  z-index: 2;
  display: none;
}

.c-dropdown-search-results.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-dropdown-search-results ul {
  padding-inline-start: 0;
  width: 100%;
  margin: 0;
  margin-top: 0;
  margin-bottom: 0.8em;
}

.c-dropdown-search-results__item {
  display: block;
  width: 100%;
  height: fit-content;
  border-radius: 0.5em;
  padding: 0.8em 1em;
  margin: 1em 0;
  background-color: #ededed88;
}

.c-dropdown-search-no-data__item {
  display: block;
  width: 100%;
  height: fit-content;
  border-radius: 0.5em;
  padding: 0.8em 1em;
  margin: 1em 0;
  font-family: "PlusJakartaSans-Regular";
  font-size: 1.1em;
  background-color: #ededed88;
}

.c-dropdown-search-results__category {
  font-family: "PlusJakartaSans-Light";
  font-size: 1em;
  letter-spacing: 1.4;
  display: inline-block;
  background-color: #7c7c7c;
  width: fit-content;
  padding: 0.1em 0.5em;
  border-radius: 0.5em;
  color: #ffffff;
}

.c-dropdown-search-results___link {
  display: block;
  width: 100%;
  height: inherit;
  font-family: "PlusJakartaSans-Regular";
  font-size: 1.1em;
  letter-spacing: 1.4;
  text-decoration: none;
  color: #000000;
}

.c-dropdown-search-results__item:first-child {
  margin-top: 0;
}

.c-dropdown-search-results__item:last-child {
  margin-bottom: 0;
}

/*Cards (buildings, objects, parkings, libraries, deans offices, student houses, services, news)*/

.c-card a {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

.c-card .c-card__img {
  border-radius: 1rem;
  box-shadow: 5px 8px 33px -17px #00000040;
  transform: scale(1.05);
  aspect-ratio: 9 / 6;
}

.c-card .c-card__body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: .75rem;
  color: #000000;
  font-size: .95rem;
}

.c-card .c-card__body .c-card__title {
  font-size: 2rem;
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

.c-card__date {
  display: inline-block;
  width: fit-content;
  padding: .25rem .5rem;
  border-radius: .5rem;
  margin-bottom: 0;
  font-family: "PlusJakartaSans-Regular";
  font-size: .8rem;
  background-color: #35383c;
  color: #ffffff;
}

.c-card .c-card__body .c-card__btn-more {
  width: fit-content;
  align-self: flex-end;
  text-align: center;
  padding: 1rem;
  box-shadow: 3px 4px 19px -12px #42445a;
  border: none;
  border-radius: 50%;
  background-color: #35383c;
}

/*Cards (different direction)*/

.c-card--direction {
  flex-direction: row;
}

.c-card__body.c-card__body--direction {
  gap: 0;
}

.c-card__body.c-card__body--direction p {
  margin-bottom: .5rem;
}

.c-card__opening_hours {
  margin-top: .5rem;
  margin-left: .5rem;
}

.c-card__img--direction {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 5px 8px 33px -17px #00000040;
  transform: scale(1.05);
}

/*Media css selectors for cards*/

@media only screen and (max-width: 991px) {
  .text--category-header {
    padding-inline: 2rem;
  }

  .l-description-box {
    width: 90%;
  }

  .l-description-box p {
    margin-left: 0;
  }

  .no-data-info {
    margin-bottom: 2rem;
  }

  .no-data-info span {
    text-align: center;
  }

  .no-data-info span:nth-of-type(2) {
    text-align: center;
  }

  .no-data-info img {
    width: 70%;
    margin-top: 1.5rem;
  }

  .c-card .c-card__body {
    padding: 1.5rem;
  }

  .c-card--direction {
    flex-direction: column;
  }
}
