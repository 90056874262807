.btn--open-detailed-description, .btn--close-detailed-description {
  border-radius: 5px;
  padding: 10px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  align-self: flex-end;
  border: none;
}

.btn--close-detailed-description {
  position: relative;
  box-shadow: none;
  border: none;
}

.c-detailed-description-container--open {
  position: absolute;
  right: 0;
  display: block;
  height: 100vh;
  width: 40%;
  border-radius: 20px 0 0 20px;
  padding: 3em;
  padding-left: 2em;
  background-color: #FFFFFF;
  color: #000000;
}

.c-detailed-description-container--open h2 {
  margin-top: 1.3em;
  font-family: "PlusJakartaSans-Regular";
  font-size: 1.9em;
  padding-left: .5em;
}

.c-detailed-description-container--open span {
  display: block;
  width: 95%;
  height: 2px;
  margin-left: .5em;
  margin-top: 1em;
  border-radius: 3em;
  background-color: #000000f4;
  opacity: .8;
}

.c-detailed-description__content {
  height: 85%;
  margin-top: 1.4em;
  margin-left: .4em;
  line-height: 1.8;
  overflow-y: auto;
  padding: .5em;
}

.c-detailed-description__content a {
  font-family: "PlusJakartaSans-Regular";
  color: #000000;
}

.c-detailed-description-container {
  display: none;
  opacity: 0;
  visibility: hidden;
  overscroll-behavior: none;
}

/*Media css selectors*/

@media only screen and (max-width: 480px) {
  .c-detailed-description-container--open {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 70%;
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    bottom: 0;
    border-radius: 20px 20px 0 0;
  }

  .c-detailed-description-container--open h2 {
    margin-top: .5em;
  }

  .c-detailed-description-container--open span {
    height: 2px;
  }

  .btn--open-detailed-description {
    position: absolute;
    padding: .5em;
    bottom: 40px;
    right: 20px;
    border-radius: 5px;
  }

  .btn--close-detailed-description {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  .btn--close-detailed-description img {
    transform: rotate(90deg);
  }

  .c-detailed-description__content {
    height: 75%;
    overscroll-behavior: contain;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1025px) {
  .c-detailed-description-container--open {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 70%;
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    bottom: 0;
    border-radius: 20px 20px 0 0;
  }

  .c-detailed-description-container--open h2 {
    margin-top: .5em;
  }

  .c-detailed-description-container--open span {
    height: 2px;
  }

  .btn--open-detailed-description {
    position: absolute;
    padding: .5em;
    bottom: 40px;
    right: 20px;
    border-radius: 5px;
  }

  .btn--close-detailed-description {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  .btn--close-detailed-description img {
    transform: rotate(90deg);
  }

  .c-detailed-description__content {
    height: 85%;
    overscroll-behavior: contain;
  }
}

