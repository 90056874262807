.c-buildings-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #35383D;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.c-buildings-header:hover {
  cursor: pointer;
}

.c-buildings-header__buildings-number {
  position: absolute;
  top: -.8em;
  right: -.8em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #888888;
  color: #ffffff;
  font-size: 1rem;
  padding: .5rem;
}

.c-buildings-header__label {
  font-size: 1.3rem;
  color: #ffffff;
  margin: .5rem;
}

.c-buildings-list-container--open {
  overflow: hidden;
}

.c-buildings-list-container--open a {
  text-decoration: none;
}

.c-buildings-list-container {
  display: none;
}