.main-img {
  position: relative;
  width: 90%;
  aspect-ratio: 9 / 6;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .5rem;
  padding: 0;
}

.small-img {
  height: 6em;
  width: 18%;
  border-radius: .5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-img:hover, .small-img:hover {
  cursor: pointer;
}

.small-img-none {
  display: none;
}

.image-btn-mobile-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: .5rem;
}

.prev-image-btn,
.next-image-btn,
.prev-image-btn-mobile,
.next-image-btn-mobile
{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: .5rem;
  background-color: #FFFFFF;
  border: 1px solid #e2e2e2;
}

.gallery-container-photos-collection-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gallery-container-box .prev-image-btn,
.gallery-container-box .next-image-btn,
.prev-image-btn-mobile,
.next-image-btn-mobile {
  display: none;
}

.gallery-navigation-container {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: center;
}

.gallery-navigation-container__list {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.gallery-navigation-dot {
  display: block;
  float: left;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFFFFF;
  margin-left: 3px;
  margin-right: 3px;
}

.gallery-current-image-navigation-dot {
  display: block;
  float: left;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFFFFF;
  opacity: 0.5;
  margin-left: 3px;
  margin-right: 3px;
}

@media only screen and (max-width: 991px) {
  .main-img {
    width: 100%;
    height: 15em;
    border-radius: 10px;
  }
  .small_img {
    display: none;
  }
  .gallery-container-photos-collection-box {
    display: none;
  }
  .gallery-container-box .prev-image-btn,
  .gallery-container-box .next-image-btn {
    display: none;
  }

  .prev-image-btn-mobile,
  .next-image-btn-mobile {
    display: block;
  }
}
