.c-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-img {
  width: 100%;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .c-img-container {
    display: none;
  }
}
