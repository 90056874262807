.c-accordion {
  margin-block: 2rem;
}

.c-accordion__title button {
  width: 100%;
  padding-block: 1rem;
  padding-inline: 2rem;
  border-radius: 1rem;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.c-accordion__title button span {
  font-family: "PlusJakartaSans-Regular";
  color: #000000;
}

.c-accordion__content {
  display: none;
}

.c-accordion__content--open {
  padding-block: 1.5rem;
  padding-inline: 1rem;
  overflow: hidden;
}