.c-alert-container {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.alert {
    padding: 1rem;
}

.alert-heading {
    font-family: "PlusJakartaSans-Medium";
    color: #000000;
}