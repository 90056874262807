.c-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 60vh;
}

.c-progress {
    display: flex;
    border-radius: 50%;
}

.c-progress__circle {
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    scale: 0;
    border-radius: 50%;
    background-color: #35383c;
    animation: loading 2s ease infinite;
    animation-delay: 1s;
}

@keyframes loading {
    50% {
        scale: 1;
    }
}

.c-progress__circle:nth-child(2) {
    animation-delay: 1.3s;
}

.c-progress__circle:nth-child(3) {
    animation-delay: 1.7s;
}

.c-loader__content p {
    font-size: 1.25rem;
    font-family: "PlusJakartaSans-Regular";
    color: #707070;
    margin-top: 2rem;
    text-align: center;
}