.c-article {
  padding-block: 2rem;
}

.c-article__name {
  font-size: 3rem;
  font-family: "PlusJakartaSans-Medium";
}

.c-article__content {
  padding-top: 1rem;
}

.c-article__return-link,
.c-article__return-link:visited {
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .c-article {
    width: 80%;
  }

  .c-article__name {
    font-size: 2.5rem;
  }
}
