.l-dropdown-profile-container {
  display: inline-block;
  position: relative;
}

.c-dropdown-profile__btn {
  background-color: #35383D;
  padding: .75rem 1rem;
  border-radius: .5rem;
  border: 0;
}

.c-dropdown-profile {
  position: absolute;
  right: 10px;
  background-color: #ffffff;
  border-radius: .5rem;
  border: 1px solid #5454543b;
  margin: 0;
  padding: 1em 1.5em;
  display: none;
}

.c-dropdown-profile.open {
  display: block;
  z-index: 1;
  margin-top: 1rem;
  width: max-content;
}

.c-dropdown-profile__header {
  font-size: 1.5rem;
  font-family: 'PlusJakartaSans-Medium';
  margin-block: .5rem;
}

.c-dropdown-profile__list {
  padding-inline-start: 0;
}

.c-dropdown-profile-list__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-block: .5em;
  padding-inline: 1em;
  margin-block: .25em;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: .5rem;
  transition: all .2s ease;
}

.c-dropdown-profile-list__item:hover {
  background: #5454543b;
}

.c-dropdown-sub-profile__list {
  list-style: none;
  padding-block: 1em;
}

.c-dropdown-sub-profile-list__item:first-child {
  padding-bottom: .5em;
}

.c-dropdown-sub-profile-list__item:last-child {
  padding-top: .5em;
}

/*Custom checkbox*/
.c-dropdown-profile__checkbox, .c-dropdown-sub-profile__checkbox {
	--background: #fff;
	--border: #35383D;
	--border-hover: #35383D;
	--border-active: #35383D;
	--tick: #ffffff;
	position: relative;
}

.c-dropdown-profile__checkbox input, .c-dropdown-sub-profile__checkbox input {
	-webkit-appearance: none;
	-moz-appearance: none;
	position: relative;
	background: var(--background);
	border: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	border-radius: 4px;
	transition: box-shadow .3s;
	box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
  margin-right: 1.5em;
  transform: scale(1.5);
}

.c-dropdown-profile__checkbox input:hover, .c-dropdown-sub-profile__checkbox input:hover {
	--s: 1.5px;
	--b: var(--border-hover);
}

.c-dropdown-profile__checkbox input:checked, .c-dropdown-sub-profile__checkbox input:checked {
	--b: var(--border-active);
}

.c-dropdown-profile__checkbox input, .c-dropdown-profile__checkbox svg,  .c-dropdown-sub-profile__checkbox input, .c-dropdown-sub-profile__checkbox svg {
	width: 17px;
	height: 17px;
	display: block;
}

.l-dropdown-sub-profile__checkbox {
  display: flex;
  align-items: center;
  font-family: 'PlusJakartaSans-Regular';
}

.l-dropdown-sub-profile__checkbox img {
  margin-left: 1rem;
  cursor: help;
}

.c-dropdown-profile__checkbox svg, .c-dropdown-sub-profile__checkbox svg {
	pointer-events: none;
	fill: none;
	stroke-width: 3px;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke: var(--stroke, var(--border-active));
	position: absolute;
	transform: scale(var(--scale, 1)) translateZ(0);
}

.c-dropdown-profile__checkbox.path input:checked, .c-dropdown-sub-profile__checkbox.path input:checked {
	--s: 2px;
	transition-delay: 0.4s;
}

.c-dropdown-profile__checkbox.path input:checked + svg, .c-dropdown-sub-profile__checkbox.path input:checked + svg {
	--a: 16.1 86.12;
	--o: 102.22;
}

.c-dropdown-profile__checkbox.path svg, .c-dropdown-sub-profile__checkbox.path svg {
	stroke-dasharray: var(--a, 86.12);
	stroke-dashoffset: var(--o, 86.12);
	transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
}

.c-dropdown-profile__checkbox.bounce, .c-dropdown-sub-profile__checkbox.bounce {
	--stroke: var(--tick);
}

.c-dropdown-profile__checkbox.bounce input:checked, .c-dropdown-sub-profile__checkbox.bounce input:checked {
	--s: 11px;
}

.c-dropdown-profile__checkbox.bounce input:checked + svg, .c-dropdown-sub-profile__checkbox.bounce input:checked + svg {
	animation: bounce 0.4s linear forwards 0.2s;
}

.c-dropdown-profile__checkbox.bounce svg, .c-dropdown-sub-profile__checkbox.bounce svg {
	--scale: 0;
}

@keyframes bounce {
	50% {
		transform: scale(1.2);
	}
	75% {
		transform: scale(.9);
	}
	100% {
		transform: scale(1);
	}
}

.c-help-text__container {
  position: relative;
  display: block;
}

.c-help-text {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #4a4a4a;
  border-radius: .5rem;
  margin: 0;
  padding: 1rem 1.5rem;
  color: #ffffff;
  font-size: .9rem;
  display: none;
}

.c-help-text.open {
  display: block;
  z-index: 2;
  min-width: 10vw;
}

@media only screen and (max-width:480px) {
  .c-dropdown-profile.open {
    width: 90vw;
  }

  .c-help-text.open {
    width: 70vw;
  }
}