.popup {
  width: 100%;
  height: 100%;
  background-color: #00000088;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.popup__btn-close {
  position: absolute;
  z-index: 3;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #f5f4f4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-img__container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.popup__btn {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 8px 8px 20px -15px #56565a;
  border: 1px solid #e2e2e2;
  margin: 1em;
}

.popup__btn-prev-img {
  margin-left: 2vw;
}

.popup__btn-next-img {
  margin-right: 2vw;
}

.popup__btn-next-img:disabled,
.popup__btn-prev-img:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.popup__img {
  width: auto;
  max-width: 70%;
  height: 70vh;
  object-fit: cover;
  display: block;
  padding: 20px 0 20px;

}

.popup__counter {
  position: absolute;
  bottom: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 8px 8px 20px -15px #56565a;
  border: 1px solid #e2e2e2;
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .popup-img__container {
    position: static;
  }

  .popup__counter {
    bottom: 40px;
  }

  .popup__img {
    height: 70vh;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .popup-img__container {
    position: static;
  }

  .popup__counter {
    bottom: 40px;
  }

  .popup__img {
    height: 100vh;
  }
}
