.c-objects-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #35383D;
  box-shadow: 4px 4px 18px -11px #42445a;
  border-radius: .9em;
  margin-bottom: 1em;
}

.c-objects-header:hover {
  cursor: pointer;
}

.c-objects-header__objects-number {
  position: absolute;
  top: -.8em;
  right: -.8em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #888888;
  box-shadow: 4px 4px 18px -11px #42445a;
  width: 2em;
  height: 2em;
  color: #ffffff;
  font-size: .8em;
  padding: .3em;
}

.c-objects-header__label {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.2em;
  margin: .4em;
}

.c-objects-list-container--open {
  overflow: hidden;
}

.c-objects-list-container--open a {
  text-decoration: none;
}

.c-objects-list-container {
  display: none;
}