/*Search home input and button*/

.l-search-container--home {
  width: 120%;
}

/*Info container*/

.l-info-container {
  display: flex;
  justify-content: center;
  padding: .5em 2em 2em 2em;
}

.l-info-container-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.l-info-container-box p:first-of-type {
  font-family: "PlusJakartaSans-Medium";
  font-size: 3em;
}

.l-info-container-box p:nth-child(2) {
  font-size: 1.2em;
  color: #707070;
}

/*Sign container*/

.l-sign-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em;
  padding-top: 0;
}

.l-sign-container img {
  margin: 3em;
}


/*Cards*/

.l-cards-list {
  justify-content: center;
  padding-left: 3.5vw;
  padding-right: 4.5vw;
}

.c-card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  border: 0;
  box-shadow: 5px 8px 33px -17px #00000040;
  border-radius: 1em;
  margin: .9em;
  margin-bottom: 1.4em;
}

/*Cards (home page)*/

.c-card--home {
  transition: transform .5s, -moz-transform .5s, -o-transform .5s;
  border: 1px solid #00000018;
  box-shadow: none;
}

.c-card--home:hover {
  transform: scale(1.1);
}

.c-card__img--home {
  border-radius: 0;
}

.c-card__link--home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 2.2rem 1.2rem 1.2rem 1.2rem;
}

.c-card__title--home {
  font-size: 1.1em;
  font-family: "PlusJakartaSans-Regular";
  font-weight: 400;
  color: #000000;
  margin-bottom: 0;
  padding: 1.2rem .6rem .6rem .6rem;
  text-align: center;
}

/*Articlescontainer*/

.l-articles-container {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

/*FAQ container*/

.l-faq-container {
  display: flex;
  justify-content: center;
  padding-top: 4em;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .l-info-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .l-sign-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }

  .l-info-container {
    display: none;
  }

  .c-card {
    margin: 1.3em;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .l-home-decorative-container {
    display: none;
  }
}