.l-typhlomaps-area-container a {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

.btn-area {
  width: fit-content;
  border: 1px solid #000000;
  border-radius: .5rem;
  font-family: "PlusJakartaSans-Medium";
  font-weight: 700;
  color: #000000;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.btn-area span {
  text-align: center;
  padding-inline: 1rem;
}

.btn-area--disabled {
  opacity: 0.4;
}

/*Media css selectors*/

@media only screen and (max-width: 720px) {
  .btn-area span {
    display: none;
  }
}
