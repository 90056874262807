.l-small-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 10px;
}

.c-small-gallery-start-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.c-small-gallery-start-container__box {
  width: 29%;
  aspect-ratio: 9 / 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 7px 2px 12px -6px #7b7d8a;
  cursor: pointer;
}

.c-small-gallery-start-more-btn-container {
  background-color: #0000003d;
  width: 100%;
  height: 100%;
  border-radius:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.c-small-gallery-start-more-btn-container__btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  width: 2.3em;
  height: 1.7em;
  background-color: #ffffffe6;
  border-radius: .5em;
  border: 0;
}

.c-small-gallery-start-container__box p {
  color: #ffffff;
  font-size: 1.5em;
}

@media only screen and (max-width: 991px) {
  .c-small-gallery-start-container__box {
    width: 42%;
  }
}

@media only screen and (max-width: 525px) {
  .c-small-gallery-start-container__box {
    width: 80%;
  }
  .c-small-gallery-start-container {
    justify-content: center;
  }
}
