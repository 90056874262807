.c-accessibility-info__container {
  align-items: center;
  word-break: break-word;
  border-radius: 1rem;
  padding: 1.5rem;
}

.c-accessibility-info__container div:nth-child(1) {
  display: flex;
  justify-content: center;
}

.c-accessibility-info__header {
  margin-block: 0;
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

.c-accessibility-info__content {
  margin-top: .5rem;
  color: #000000;
}

.c-accessibility-info__container--inaccessible {
  background-color: #E9160D;
}

.c-accessibility-info__container--semi-access {
  background-color: #fcff37;
}

.c-accessibility-info__container--full-access {
  background-color: #008926;
}

/*Media css selectors*/

@media only screen and (max-width: 1200px) {
  .c-accessibility-info__container {
    flex-direction: column;
  }

  .c-accessibility-info__container div:nth-child(2) {
    margin-top: 0.5rem;
    text-align: center;
  }
}
