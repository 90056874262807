/*Sidebar*/
.c-sidebar-menu {
  position: relative;
  height: fit-content;
  border-radius: 1rem;
  padding: 2rem 1.3rem;
  background-color: #35383c;
}

.btn--toggle-sidebar {
  position: absolute;
  top: 20px;
  right: -50px;
  background-color: #181818;
  border-radius: 1rem;
  padding: 1em 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}

.c-sidebar-menu-list {
  list-style-type: none;
  padding: 0;
  outline: 0;
}

.c-sidebar-menu-list__item {
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-block: .5rem;
  padding: 1rem 1.5rem;
  color: #c3c3c3;
}

.c-sidebar-menu-list__item:hover {
  color: #ffffff;
}

.c-sidebar-menu-item__icon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 1.5rem;
}

.c-sidebar-menu-item__icon--toggle-sidebar {
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 0;
}

.c-sidebar-menu-list__item img {
  width: 100%;
  height: 100%;
}

.c-sidebar-menu-list__item span {
  display: block;
  white-space: normal;
}

.c-sidebar-menu-list__item.active, .c-subsidebar-menu-list__item.active {
  background-color: #181818;
  border-radius: 1rem;
}

.active span, .c-subsidebar-menu-list__item.active {
  font-family: 'PlusJakartaSans-Medium';
  color: #FFFFFF;
}

/*Subsidebar*/

.c-subsidebar-menu {
  display: none;
}

.c-subsidebar-menu--open {
  display: flex;
}

.c-subsidebar-menu--open span {
  width: 3px;
  height: auto;
  background-color:#ececec75;
  border-radius: 1rem;
  margin-left: 3rem;
  margin-block: .5rem;
}

.c-subsidebar-menu-list {
  position: relative;
  width: 100%;
  margin-block: .5rem;
  padding: .5rem;
  margin-left: 1.5rem;
  list-style: none;
}

.c-subsidebar-menu-list li {
  padding: .3rem;
}

.c-subsidebar-menu-list__item {
  display: block;
  white-space: normal;
  text-decoration: none;
  color: #ffffff;
  padding: 1rem;
}

/*Mobile sidebar content*/
.c-object-sidebar-mobile-container {
  display: none;
}

.c-object-sidebar-mobile-container--open {
  display: none;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  /*Sidebar*/
  .c-sidebar-menu {
    width: 100%;
    padding: .5rem;
    padding-top: 0;
    background-color: transparent;
  }

  .btn--toggle-sidebar {
    display: none;
  }

  .c-sidebar-menu-list__item {
    background-color:#35383c;
    border-radius: 1rem;
    margin-top: 1em;
  }

  /*Subsidebar*/
  .c-subsidebar-menu--open {
    display: none;
  }

  /*Mobile sidebar content*/
  .c-object-sidebar-mobile-container--open {
    display: block;
  }

  .c-object-sidebar-mobile-container__content section:first-child {
    padding: .5rem;
  }

  .c-object-sidebar-mobile-container__content section:first-child a {
    color: #000000;
    font-family: 'PlusJakartaSans-Regular';
  }

}

@media not all and (pointer: coarse) {
  .c-sidebar-menu-list__item:hover, .c-subsidebar-menu-list__item:hover {
    background-color:#181818;
    border-radius: 1rem;
  }

  .c-sidebar-menu-list__item:hover span, .c-subsidebar-menu-list__item:hover {
    font-family: 'PlusJakartaSans-Regular';
    color: #ffffff;
  }
}