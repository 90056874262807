.c-micronavigation-box {
  position: relative;
  display: flex;
  justify-content: center;
}

.c-micronavigation-box__bg {
  z-index: -1;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ebebeb;
  border-radius: 1rem;
  width: 100%;
  height: 75%;
}

.c-micronavigation-box__info {
  padding: 4rem;
}

.c-micronavigation-box__app-view img {
  width: 45em;
}

.c-micronavigation-box__header h3 {
  font-family: "PlusJakartaSans-Medium";
  font-size: 2rem;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

.c-micronavigation-box__content p {
  font-family: "PlusJakartaSans-Light";
  line-height: 1.7;
}

.c-micronavigation-box__content p a {
  font-family: "PlusJakartaSans-Medium";
  color: #000000;
}

.c-micronavigation-box__apps {
  display: flex;
  gap: .5rem;
}

@media only screen and (min-width: 991px) and (max-width: 1400px) {
  .c-micronavigation-box {
    flex-direction: column;
    justify-content: center;
    background-color: #ebebeb;
    height: fit-content;
  }

  .c-micronavigation-box__bg {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .c-micronavigation-box {
    flex-direction: column;
    background-color: #ebebeb;
    border-radius: 1rem;
    margin-block: 2rem;
  }

  .c-micronavigation-box__bg {
    display: none;
  }

  .c-micronavigation-box__info {
    padding: 2rem;
  }

  .c-micronavigation-box__header h3 {
    padding-top: 0;
  }

  .c-micronavigation-box__apps {
    flex-direction: column;
    gap: 1rem;
  }
}
