.c-no-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.c-no-found-container h2 {
  font-size: 3rem;
  text-align: center;
}

.c-no-found-container p {
  font-size: 1.2rem;
  padding-top: 1rem;
  text-align: center;
}

.c-bnt--go-to-home-page {
  border: 0;
  text-decoration: none;
  text-align: center;
  background-color: #35383D;
  border-radius: .5rem;
  color: #ffffff;
  padding: .75rem 1rem;
  margin: 1.5rem;
}