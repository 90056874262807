.l-call-to-action-btn-container {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    -webkit-backface-visibility: hidden;
}

.btn--call-to-action {
    position: relative;
    bottom: 2rem;
    right: 2rem;
    width: 5rem;
    height: 5rem;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    text-align: center;
    box-shadow: 4px 4px 18px -11px #42445a;
    display: none;
}

.btn--call-to-action.visible {
    display: inline;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
    .l-call-to-action-btn-container {
        right: 0;
        bottom: 0;
    }
}