.c-modal-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000056;
}

.c-modal {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #ffffff;
  border: 0;
  padding: 3rem;
}

.c-modal__header p {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.5rem;
}

.c-modal__description p {
  margin-bottom: 0;
}

.c-btn--modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #f5f4f4;
}

.c-modal__form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.c-modal__form form {
  width: 100%;
}

.l-modal-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
}

.c-btn--modal-submit {
  width: fit-content;
  text-align: center;
  font-family: "PlusJakartaSans-Medium";
  color: #ffffff;
  background-color: #35383d;
  border: 0;
  border-radius: 0.5rem;
  padding: 0.5rem 3.5rem;
}

.c-btn--modal-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.form-group {
  margin-block: 0.5rem;
}

.form-group label {
  margin: 0.5rem;
  font-family: "PlusJakartaSans-Medium";
}

.form-group span {
  color: #ff0000;
}

.input-form {
  display: block;
  width: 100%;
  line-height: 1.5;
  color: #000000;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
  .form-row {
    flex-direction: column;
  }

  .c-modal {
    width: 90%;
    padding: 1.5rem;
  }

  .c-modal__header p {
    font-size: 1.5rem;
    font-family: "PlusJakartaSans-Regular";
    margin-bottom: 1rem;
  }

  .c-modal__form {
    padding: 0;
  }

  .form-group {
    margin-block: 0.5rem;
  }
}
