.l-select {
  display: flex;
  justify-content: center;
}

.l-select-container {
  position: relative;
  width: 100%;
}

.c-select-box__btn {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .5rem;
  background-color: #f1f1f1;
  border: 1px solid #cecece;
  color: #656565;
  font-family: "PlusJakartaSans-Regular";
  padding: 0.5rem 1rem;
  text-align: left;
}

.c-select-box__listbox {
  z-index: 1;
  position: absolute;
  width: inherit;
  list-style: none;
  background-color: #f1f1f1;
  border: 1px solid #cecece;
  border-radius: 0.25rem;
  display: none;
  list-style: none;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  height: 300%;
  overflow-y: scroll;
}

/* width */
.c-select-box__listbox::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.c-select-box__listbox::-webkit-scrollbar-track {
  border-radius: 1rem;
}

/* Handle */
.c-select-box__listbox::-webkit-scrollbar-thumb {
  background-color: #898989;
  border-radius: 1rem;
}

.c-select-box__listbox.show {
  display: block;
}

.c-select-box__listbox li {
  font-family: "PlusJakartaSans-Regular";
  color: #656565;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.c-select-box__listbox li:last-of-type {
  margin: 0 0;
}

.c-select-box__listbox li:active,
.c-select-box__listbox li:focus,
.c-select-box__listbox li:hover,
.c-select-box__listbox li[aria-selected="true"] {
  background-color: #35383d;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
}
