.l-pagination {
  display: flex;
  justify-content: center;
}

.c-pagination__list {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
}

.c-pagination .c-pagination__item {
  display: inline-block;
}

.c-pagination .c-pagination__item .c-pagination__link {
  padding: 6px 8px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 8px;
  margin-left: 0;
  margin-right: 8px;
  text-decoration: none;
  color: #8a93a6;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.c-pagination .c-pagination__item .c-pagination__link:hover {
  background-color: #f6f7fb;
  color: #35383c;
}

.c-pagination .c-pagination__item.disabled .c-pagination__link {
  background-color: #f6f7fb;
  color: #272727;
  cursor: not-allowed;
}

.c-pagination .c-pagination__item:first-child .c-pagination__link,
.c-pagination .c-pagination__item:last-child .c-pagination__link {
  background-color: #f6f7fb;
}

.c-pagination .c-pagination__item.active .c-pagination__link {
  background-color: #35383c;
  border-color: #35383c;
  color: #ffffff;
}

.c-pagination__prev-page-btn {
  display: inline-block;
  background-color: #35383c;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin-right: 0.5rem;
}

.c-pagination__next-page-btn {
  display: inline-block;
  background-color: #35383c;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin-left: 0.5rem;
}

.c-pagination__prev-page-btn:disabled,
.c-pagination__next-page-btn:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
