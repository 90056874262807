.c-room-plate {
  width: 90%;
}

.c-room-plate__building-name {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.5rem;
  text-transform: uppercase;
}

.c-room-plate__number {
  font-family: "PlusJakartaSans-Medium";
  font-size: 10rem;
  text-align: center;
}

.c-room-plate__name {
  font-family: "PlusJakartaSans-Medium";
  font-size: 4.5rem;
  text-align: center;
  text-transform: uppercase;
}

.c-room-plate__subname {
  font-family: "PlusJakartaSans-Regular";
  font-size: 3rem;
  text-align: center;
  color: #616161;
  text-transform: uppercase;
  margin-top: 1rem;
}

.c-room-plate__subsubname {
  font-family: "PlusJakartaSans-Regular";
  font-size: 1.8rem;
  text-align: center;
  color: #727272;
  margin: 0;
  margin-top: 0.5rem;
}

.c-room-plate__level {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.c-room-plate__level p:first-child {
  font-family: "PlusJakartaSans-Medium";
  margin-right: 0.5rem;
}

.c-room-plate__additional-info-header {
  font-family: "PlusJakartaSans-Medium";
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.c-room-plate-link-to-description a {
  font-family: "PlusJakartaSans-Regular";
}

/*Room plate additional information*/

.c-room-plate td {
  padding: 0.5rem;
  border: 1px solid #6d6d6d6b;
}

.c-room-plate table thead tr td {
  font-family: "PlusJakartaSans-Regular";
}

.c-room-plate table thead {
  background-color: #35383d;
  color: white;
}

.c-room-plate a:hover {
  color: #000000;
  font-family: "PlusJakartaSans-Medium";
}

@media only screen and (max-width: 390px) {
  .c-room-plate__building-name {
    font-size: 1.3rem;
  }

  .c-room-plate__number {
    font-size: 4rem;
  }

  .c-room-plate__name {
    font-size: 1.45rem;
  }

  .c-room-plate__subname {
    font-size: 1.4rem;
  }

  .c-room-plate__subsubname {
    font-size: 1.1rem;
  }

  .c-room-plate__additional-info-header {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 390px) and (max-width: 450px) {
  .c-room-plate__building-name {
    font-size: 1.3rem;
  }

  .c-room-plate__number {
    font-size: 5rem;
  }

  .c-room-plate__name, .c-room-plate__subname {
    font-size: 1.7rem;
  }

  .c-room-plate__subsubname {
    font-size: 1.2rem;
  }

  .c-room-plate__additional-info-header {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 450px) and (max-width: 767px) {
  .c-room-plate__number {
    font-size: 5rem;
  }

  .c-room-plate__name {
    font-size: 2rem;
  }

  .c-room-plate__subname {
    font-size: 1.9rem;
  }

  .c-room-plate__subsubname {
    font-size: 1.3rem;
  }

  .c-room-plate__additional-info-header {
    font-size: 1.1rem;
  }
}

