.c-faq-accordion {
    margin-top: 2rem;
    border-top: .6px solid #8e8e8e37;
}

.c-faq-accordion:last-of-type {
    margin-bottom: 2rem;
}

.c-faq-accordion__title button {
    display: block;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-inline: 2rem;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 0;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
}

.c-faq-accordion__content--open {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 1.5rem;
}

.c-faq-accordion__content--open p {
    overflow: hidden;
    color: #707070;
    line-height: 1.7;
}

.c-faq-accordion__content--open a, .c-faq-accordion__content--open strong {
    color: #000000;
}

.c-faq-accordion__title button span {
    color: #000000;
    font-family: 'PlusJakartaSans-Medium';
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.c-faq-accordion__title button span img {
    margin-right: 2rem;
    padding: .2rem;
}

.c-faq-accordion__content {
    display: none;
}

/*Media css selectors*/

@media only screen and (max-width: 991px) {
    .c-faq-accordion__title button {
        padding-inline: 0;

    }

    .c-faq-accordion__content--open {
        padding-inline: 0;
        padding-bottom: 0;
    }

    .c-faq-accordion__title button span img {
        margin-right: 1rem;
    }
}